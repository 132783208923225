import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import React, {useEffect, useRef, useState} from "react";
import { useSnapshot } from "valtio";
import BlockUI from '@components/BlockUI/BlockUI';
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import ReglageStep from "../../../views/SpeedBusinessView/ReglageStep/ReglageStep";
import PersonnalisationTablesStep
    from "../../../views/SpeedBusinessView/PersonnalisationTablesStep/PersonnalisationTablesStep";
import DestinatairesStep from "../DestinatairesStep/DestinatairesStep";
import toast from "react-hot-toast";
import MailRappelStep from "../../../views/SpeedBusinessView/MailRappelStep/MailRappelStep";

function EditModal() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    let queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const speedSnap = useSnapshot(speedBusinessProxy);
    const [termine, setTermine] = useState(false);

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) =>  <ReglageStep ref={ref}/>
        },
        {
            name: 'Personnalisation des tables (optionnel)',
            renderComponent: (ref) => <PersonnalisationTablesStep ref={ref}/>
        },
        {
            name: 'Participants',
            renderComponent: (ref) => <DestinatairesStep termine={termine} setTermine={setTermine} baseRoute='rencontreSpeedBusiness' id={speedSnap.speedBusiness?.id} />
        },
        {
            name: 'Mail de rappel',
            renderComponent: (ref) => <MailRappelStep baseRoute='rencontreSpeedBusiness' id={speedSnap.speedBusiness?.id} />,
        },
    ];

    async function hideValidation() {

        const result = await BtSwal.fire({
            title: 'Voulez-vous quitter ?',
            text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
            icon: 'question',
            showDenyButton: true,
            denyButtonText: 'Quitter',
            confirmButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-secondary',
                denyButton: 'btn btn-danger',
            },
        });
        if (result.isDenied){
            setTermine(false);
            speedBusinessProxy.showEditSpeedBusinessModal = false;
        }
        queryClient.invalidateQueries('participants');
        queryClient.invalidateQueries('rencontreSpeedBusiness');
    }

    async function goToStep(step) {
        setCurrentIndex(step);
        //todo a adapter pour les participants
        if (step == 2 && speedSnap?.speedBusiness?.idInvitation){
            toast((t) => (
                    <div style={{
                        color: "var(--primary-color)"
                    }} className="text-center">
                        <i style={{
                            color: "var(--primary-color)"
                        }} className="fa m-auto fa-info-circle fa-3x"></i>
                        <p>Cette liste représente les destinataires de l'invitation sélectionnée.</p>
                        <p>Vous pouvez afficher tous vos contacts en cliquant sur le bouton "Voir tous les contacts" à gauche de l'écran.</p>
                        <Button
                            onClick={() =>toast.dismiss(t.id) }
                        >
                            ok
                        </Button>
                    </div>
                ), {
                    id: 'clipboard',
                    style: {
                        position: "relative",
                        top: 100,
                        boxShadow: "0 0 15px #504f4c",
                        fontWeight: "bold"
                    },
                    duration: 15000
                }
            );
        }
        // let checkDestinataire = false;
        // if (step === 3 && speedSnap.destinataireIsChecked){
        //     let message = "Attention des destinataires sélectionnés n'ont pas été ajoutés. Souhaitez-vous tout de même continuer ?"
        //     if (!speedSnap.destinataireAlertIsAdd){
        //         message = "Attention des destinataires sélectionnés n'ont pas été supprimés. Souhaitez-vous tout de même continuer ?"
        //     }
        //     await BtSwal.fire({
        //         title: message,
        //         showDenyButton: true,
        //         confirmButtonText: `Oui`,
        //         denyButtonText: `Non`,
        //         icon : 'info'
        //     }).then((result) => {
        //         if (!result.isConfirmed){
        //             checkDestinataire = true;
        //         }
        //
        //     })
        // }
        // if (checkDestinataire){
        //     return false;
        // }
        if (stepRef.current?.save != null) {
            speedBusinessProxy.loading = true;
            if (await stepRef.current.save()) {
                speedBusinessProxy.step = step;
            }
            speedBusinessProxy.loading = false;
        }else {
            speedBusinessProxy.step = step;
        }
    }

    return <Modal
        fullscreen
        show={speedSnap.showEditSpeedBusinessModal}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === speedSnap.step ? 'current' : ''} ${index < speedSnap.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={speedSnap.loading} className='overflow-scroll modal-body'>
            {steps[speedSnap.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {speedSnap.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => goToStep(speedSnap.step - 1)}
            >Précédent</Button>}
            {speedSnap.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(speedSnap.step + 1);
                }}
            >Suivant</Button>}
            {speedSnap.step == steps.length - 1
                && <Button
                    text='Terminer'
                    variant='secondary'
                    className='ms-auto'
                    onClick={async () => {
                        setTermine(true)
                        speedBusinessProxy.showEditSpeedBusinessModal = false
                    }}
                >Terminer</Button>
            }
        </Modal.Footer>
    </Modal>;
}

export default EditModal;