import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeMailMaitreDuTempsRencontre(props) {
    return useMutation(changeMail, props);
}

export async function changeMail(mail) {
    return api.post('emailMaitreTempsRencontreSpeedBusiness', { json: mail }).json();
}
