import {useSnapshot} from "valtio";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useQueryClient} from "react-query";
import auth from "../../../../services/auth";
import {Form} from "react-bootstrap";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import invitationsProxy from "../../../../proxies/invitations";
import Select2 from "../../../../components/Select2/Select2";
import useValidPresence from "../../../../requests/communication/speedBusiness/useValidPresence";
import useGetQueryParticipants from "../../../../requests/communication/speedBusiness/useGetQueryParticipants";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import annuaireProxy from "../../../../proxies/annuaire";
import LoadingView from "../../../../views/LoadingView/LoadingView";

const Presences = forwardRef((_, ref) => {

    const speedSnap = useSnapshot(speedBusinessProxy);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const [idEtatPresence, stateIdEtatPresence] = useState("");
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const validerPresence= useValidPresence();
    const participantsPresentEtEnRetard = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                //0==Présence 1==Absence 2==En retard
                value: "0,2",
                regex: false,
            },
        },
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            search: {
                //1==false 0==true
                value: "",
                regex: false,
            },
        },
        "presentEtEnRetard"
    );
    useEffect(() => {
        stateIdEtatPresence("")
        setPrenom("")
        setPrenomNom("")
        setNom("")
    }, [speedSnap.showJourJModal]);

    const colSearch = useMemo(() => {
        return {
            'etatPresence': idEtatPresence,
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
        };
    }, [idEtatPresence, prenom, nom, prenomNom]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = true;
            if (participantsPresentEtEnRetard?.data?.nbLignesTotales < (speedSnap?.speedBusiness?.nombreTablesChoisies * speedSnap?.speedBusiness?.nombrePersonnesMinParTable)){
                await BtSwal.fire(
                    {
                        title: "Attention le nombre minimum de présent et en retard ("+speedSnap?.speedBusiness?.nombreTablesChoisies * speedSnap?.speedBusiness?.nombrePersonnesMinParTable+") n'a pas été atteint !",
                        showDenyButton: false,
                        confirmButtonText: `J'ai compris`,
                        icon: 'warning',
                    })
                done = false;
            }
            return done;
        },
    }));


    const tableRef = useRef();
    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 300,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<div class="me-7">
                        <div class="symbol symbol-fixed d-flex align-items-center position-relative">
                            <img src={row.original.participant.personne?.upload?.urlMiniature} alt="image personne"/>
                            <div className="ms-3 d-flex">
                                <p className="me-2">{row.original.participant.personne.prenom + " " + row.original.participant.personne.nom}</p>
                                <a
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                    className='svg-icon svg-icon-1 cursor-pointer'><Art005/></a></div>
                        </div>
                    </div>);
                    result.push()
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: 'Téléphones',
                accessor: 'participant.personne.tel1',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                    result.push(<br/>)
                    result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                    return result;
                },
            },
            {
                Header: 'Email',
                accessor: 'participant.personne.email',
                minWidth: 250,
            },
            {
                Header: 'Présence rencontre',
                id: 'presenceEvenement',
                accessor: 'tabEtatsPresence',
                minWidth: 240,
                maxWidth: 240,
                Cell: ({row}) => {
                    const etatsPresence = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {

                                    validerPresence.mutate({
                                        id: row.original.id,
                                        idEtatPresence: value.id
                                    })
                                    queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants'])
                                    snapAuth.websocket.send("participant")
                                    snapAuth.websocket.send( speedSnap.speedBusiness?.id)
                                    snapAuth.websocket.send("rencontreSpeedBusiness")

                                }}
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '14px'}}>{value.libelle}</span></label>
                    );

                    const etatsPresenceDisabled = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    return <div className="btn-group" data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]">
                        {true ? etatsPresence : etatsPresenceDisabled}
                    </div>
                },
                Footer: info => {
                    let tabEtatsPresenceSelect = [
                        {id: 0, text: "Présent"},
                        {id: 1, text: "Absent"},
                        {id: 2, text: "En retard"},
                    ]
                    return <Select2
                        data={tabEtatsPresenceSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre présence'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPresence(selected)
                        }}/>
                },
            },
        ]
        return tmp;
    }, [])

    if(participantsPresentEtEnRetard.isLoading){
        return <LoadingView/>
    }

    return <div style={{
        width:1100
    }} className="m-auto">
                <h3>Liste des participants</h3>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
                    keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
                    columns={columns}
                    search={debouncedSearch}
                    colSearch={colSearch}
                    showFiltres={true}/>
    </div>
});
export default Presences;
