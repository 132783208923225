import {useMemo, useState} from "react";
import {Accordion, Button, Card, Nav, Tab} from "react-bootstrap";
import AgenceSettings from "./AgenceSettings/AgenceSettings";
import ExpediteurSettings from "./ExpediteursSettings/ExpediteurSettings";
import FooterSettings from "./FooterSettings/FooterSettings";
import HeaderSettings from "./HeaderSettings.js/HeaderSettings";
import DestinataireReponseInvitationSettings
    from "./DestinataireReponseInvitationSettings/DestinataireReponseInvitationSettings";
import DestinataireValidationSettings from "./DestinataireValidationSettings/DestinataireValidationSettings";
import RappelMailSettings from "./RappelMailSettings/RappelMailSettings";
import BlocModaliteSettings from "./BlocModaliteSettings/BlocModaliteSettings";
import BlocResponseSettings from "./BlocResponseSettings/BlocResponseSettings";
import AccesMailSettings from "./AccesMailSettings/AccesMailSettings";
import motsClesProxy from "../../proxies/motsClesProxy";
import CouleursAgenceSettings from "./AgenceSettings/CouleursAgenceSettings";
import CategorieMailSettings from "./CategorieMailSettings/CategorieMailSettings";
import ParametresStripeView from "../ParametresPaiementView/ParametresStripeView";
import ParametresAdaptationMarqueView from "../ParametresPaiementView/ParametresAdaptationMarqueView";
import ParametresFacturationView from "../ParametresPaiementView/ParametresFacturationView";
import ParametresForumView from "../ParametresForumView/ParametresForumView";
import EmailInscriptionInviteSettings from "./EmailInscriptionInviteSettings/EmailInscriptionInviteSettings";
import MailJustificatifSettings from "./MailJustificatifSettings/MailJustificatifSettings";
import MailRelancePaiementSettings from "./MailRelancePaiementSettings/MailRelancePaiementSettings";
import ResponsablePlaceLimiteSettings from "./ResponsablePlaceLimiteSettings/ResponsablePlaceLimiteSettings";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import FacebookSettings from "./FacebookSettings/FacebookSettings";
import LinkedinSettings from "./LinkedinSettings/LinkedinSettings";
import ChampsPersonnaliseSettings from "./ChampsPersonnaliseSettings/ChampsPersonnaliseSettings";
import annuaire from "../../proxies/annuaire";
import EmailTableRencontreSetting
    from "./EmailTableRencontreSettings/EmailTableRencontreSettings";
import EmailRappelRencontreSetting
    from "./EmailRappelRencontreSettings/EmailRappelRencontreSettings";
import EmailMaitreDuTempsRencontreSetting
    from "./EmailMaitreDuTempsRencontre/EmailMaitreDuTempsRencontre";
import TauxDeTVAFacturation from "../../views/ParametresPaiementView/TauxDeTVAFacturation";
import MailNotifNouveauSujetForumSettings
    from "./MailNotifNouveauSujetForumSettings/MailNotifNouveauSujetForumSettings";

function ParametresView({stripe = false, facebook = false, linkedin = false}) {
    const [initStripe, setInitStripe] = useState(false);
    const [initFacebook, setInitFacebook] = useState(false);
    const [initLinkedin, setInitLinkedin] = useState(false);
    const tabsGeneral = useMemo(() => [
        { name: "Informations de l'agence", key: 'agence', component: <AgenceSettings /> },
        { name: "Couleurs de l'agence", key: 'couleursAgence', component: <CouleursAgenceSettings /> }, //todo a remettre quand le back aura avancé
        // { name: "Notifications", key: 'notifications', component: <NotificationsSettings /> },
    ]);
    const tabsAnnuaire = useMemo(() => [
        { name: "Champs personnalisés", key: 'champsPerso', component: <ChampsPersonnaliseSettings /> },
    ]);
    const tabsCom = useMemo(() => [
        { name: 'Expéditeurs des mails', key: 'mailExpediteur', component: <ExpediteurSettings /> },
        { name: 'Entêtes de mail', key: 'mailHeaders', component: <HeaderSettings /> },
        { name: 'Catégorie de mail', key: 'categorieEmail', component: <CategorieMailSettings /> },
        { name: 'Pied de mail', key: 'mailFooters', component: <FooterSettings /> },
        { name: 'Mail accès partenaire', key: 'accesPartenaireMail', component: <AccesMailSettings /> },
        // { name: 'Personnalisation des mots clés', key: 'personnalisationDesMotsCles', component: <EditKeyWordsSettings /> },
        { name: 'Destinataires des validations et/ou des notifications système\n', key: 'mailValidateur', component: <DestinataireValidationSettings /> },
    ]);
    // const tabsReseauxSociaux = useMemo(() => [
    //     { name: "Facebook", key: 'facebook', component: <FacebookSettings /> },
    //     { name: "Linkedin", key: 'linkedin', component: <LinkedinSettings /> },
    // ]);
    const tabsInvit = useMemo(() => [
        { name: 'Blocs de modalité des invitations', key: 'modaliteBloc', component: <BlocModaliteSettings /> },
        { name: 'Blocs de réponse des invitations', key: 'ResponseBloc', component: <BlocResponseSettings /> },
        { name: 'Mail de rappel', key: 'rappelMail', component: <RappelMailSettings /> },
        { name: 'Mail des justificatifs', key: 'justificatifMail', component: <MailJustificatifSettings /> },
        { name: 'Mail relance de paiement', key: 'replancePaiementMail', component: <MailRelancePaiementSettings /> },
        { name: 'Mail d\'inscription d\'un invité', key: 'emailInscriptionInvite', component: <EmailInscriptionInviteSettings /> },
        { name: 'Destinataires pour les réponses aux invitations', key: 'mailDestinataire', component: <DestinataireReponseInvitationSettings /> },
        { name: 'Responsable places limités', key: 'responsablePlacesLimites', component: <ResponsablePlaceLimiteSettings /> },
    ]);
    const tabsStripe = useMemo(() => [
        { name: "Configuration du compte", key: 'configCompte', component: <ParametresStripeView /> },
        { name: "Adaptation à votre marque", key: 'adaptationMarque', component: <ParametresAdaptationMarqueView /> },
    ]);
    const tabsFacturation = useMemo(() => [
        { name: "Taux de TVA", key: 'tauxTVAFacturation', component: <TauxDeTVAFacturation /> },
        { name: "Paramètres de facturation", key: 'paramsFacturation', component: <ParametresFacturationView /> },
    ]);
    const tabsForum = useMemo(() => [
        { name: "Paramètres du forum", key: 'paramsForums', component: <ParametresForumView /> },
        { name: "Mail de notification de nouveau sujet forum", key: 'mailNotifForums', component: <MailNotifNouveauSujetForumSettings /> },
    ]);
    const tabsRencontre = useMemo(() => [
        { name: "Email table rencontre", key: 'emailTableRencontre', component: <EmailTableRencontreSetting /> },
        { name: "Email rappel rencontre", key: 'emailRappelRencontre', component: <EmailRappelRencontreSetting /> },
        { name: "Email maitre du temps", key: 'emailMaitreDuTemps', component: <EmailMaitreDuTempsRencontreSetting /> },
    ])
    const [activeKey, setActiveKey] = useState();
    const [currentParam, setCurrentParam] = useState("");
    //Configuration spécial si ça vient de l'url /parametres/stripe
    if (stripe && !initStripe){
        setActiveKey(4)
        setCurrentParam(tabsStripe.at(0))
        setInitStripe(true)
    }
    // //configuration spécial si ça vient de l'url /parametres/compteFacebook
    // if (facebook && !initFacebook){
    //     setActiveKey(7)
    //     setCurrentParam(tabsReseauxSociaux.at(0))
    //     setInitFacebook(true)
    // }
    // //configuration spécial si ça vient de l'url /parametres/compteLinkedin
    // if (linkedin && !initLinkedin){
    //     setActiveKey(7)
    //     setCurrentParam(tabsReseauxSociaux.at(1))
    //     setInitLinkedin(true)
    // }
    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
        }
        else {
            setActiveKey(eventKey);
        }
    }
    return <Card>
        <Card.Body>
            <Accordion activeKey={activeKey} className='mt-7'>
                <Tab.Container defaultActiveKey={stripe ? tabsStripe.at(0).key : ""}>
                    <div className='d-flex row p-5'>
                        <div className='d-flex justify-content-center flex-wrap'>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(1)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(1)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 1 ? '' : 'collapsed'}`}
                                    type="button">
                                    Général
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(10)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(10)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 10 ? '' : 'collapsed'}`}
                                    type="button">
                                    Annuaire
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(11)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(11)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 11 ? '' : 'collapsed'}`}
                                    type="button">
                                    Rencontre
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(2)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(2)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 2 ? '' : 'collapsed'}`}
                                    type="button">
                                    Communication
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(3)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(3)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 3 ? '' : 'collapsed'}`}
                                    type="button">
                                    Invitation
                                </button>
                            </h2>
                            {/*<h2 className="accordion-header rounded w-auto m-2">*/}
                            {/*    <button*/}
                            {/*        onClick={() => {*/}
                            {/*            if (currentParam) {*/}
                            {/*                BtSwal.fire(*/}
                            {/*                    {*/}
                            {/*                        title: "Voulez-vous changer de paramètres ?",*/}
                            {/*                        text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",*/}
                            {/*                        showDenyButton: true,*/}
                            {/*                        confirmButtonText: `Oui`,*/}
                            {/*                        denyButtonText: `Annuler`,*/}
                            {/*                        icon: 'warning',*/}
                            {/*                    }).then((result) => {*/}
                            {/*                    if (result.isConfirmed){*/}
                            {/*                        setCurrentParam("")*/}
                            {/*                        toggleAccordionKey(7)*/}
                            {/*                    }*/}
                            {/*                })*/}
                            {/*            }else {*/}
                            {/*                setCurrentParam("")*/}
                            {/*                toggleAccordionKey(7)*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*        className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 7 ? '' : 'collapsed'}`}*/}
                            {/*        type="button">*/}
                            {/*        Réseaux sociaux*/}
                            {/*    </button>*/}
                            {/*</h2>*/}
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(6)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(6)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 6 ? '' : 'collapsed'}`}
                                    type="button">
                                    Forum
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(4)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(4)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 4 ? '' : 'collapsed'}`}
                                    type="button">
                                    Stripe
                                </button>
                            </h2>
                            <h2 className="accordion-header rounded w-auto m-2">
                                <button
                                    onClick={() => {
                                        if (currentParam) {
                                            BtSwal.fire(
                                                {
                                                    title: "Voulez-vous changer de paramètres ?",
                                                    text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                    showDenyButton: true,
                                                    confirmButtonText: `Oui`,
                                                    denyButtonText: `Annuler`,
                                                    icon: 'warning',
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    setCurrentParam("")
                                                    toggleAccordionKey(5)
                                                }
                                            })
                                        } else {
                                            setCurrentParam("")
                                            toggleAccordionKey(5)
                                        }
                                    }}
                                    className={`accordion-button w-auto fs-4 rounded bg-light fw-bold ${activeKey === 5 ? '' : 'collapsed'}`}
                                    type="button">
                                    Faturation
                                </button>
                            </h2>
                        </div>
                        <Accordion.Collapse eventKey={1}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsGeneral.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text: "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed) {
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      } else {
                                                          setCurrentParam(tab)
                                                      }

                                                      if (tab.key == "rappelMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },

                                                          ]
                                                      } else if (tab.key == "accesPartenaireMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'identifiant',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{login}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'url pour définir mot de passe',
                                                                  insert: '<span><a href="{urlDefinirUnMotDePasse}">cliquer ici</a></span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "modaliteBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "ResponseBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse accepter',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseAccepte}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse refuser',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseDecline}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse transférer',
                                                                  insert: '<span><a href="{urlTransfertInvitation}">cliquer ici</a></span>'
                                                              }
                                                          ]
                                                      } else if (tab.key == "mailFooters") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom responsable agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={10}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsAnnuaire.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }


                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={11}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsRencontre.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }
                                                      if (tab.key != "emailRappelRencontre") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'liste table par tour',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{listTableParTour}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Lien de suivi du temps (maitre du temps)',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{lienOutilSuivi}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }else {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={2}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsCom.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }

                                                      if (tab.key == "rappelMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },

                                                          ]
                                                      } else if (tab.key == "accesPartenaireMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'identifiant',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{login}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'url pour définir mot de passe',
                                                                  insert: '<span><a href="{urlDefinirUnMotDePasse}">cliquer ici</a></span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "modaliteBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "ResponseBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse accepter',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseAccepte}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse refuser',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseDecline}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse transférer',
                                                                  insert: '<span><a href="{urlTransfertInvitation}">cliquer ici</a></span>'
                                                              }
                                                          ]
                                                      } else if (tab.key == "mailFooters") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom responsable agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={3}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsInvit.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }

                                                      toggleAccordionKey(null)
                                                      if (tab.key == "rappelMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                      else if (tab.key == "justificatifMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Url justificatif',
                                                                  insert: '<span><a href="{urlJustificatif}">cliquer ici</a></span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                      else if (tab.key == "replancePaiementMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Url paiement',
                                                                  insert: '<span><a href="{urlPaiement}">cliquer ici</a></span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                      else if (tab.key == "emailInscriptionInvite") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Prénom nom hôte',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomHote}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Prénom hôte',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomHote}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom hôte',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nomHote}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Coordonnées hôte',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{coordonneesContactHote}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom Responsable',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }else if (tab.key == "accesPartenaireMail") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'identifiant',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{login}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'url pour définir mot de passe',
                                                                  insert: '<span><a href="{urlDefinirUnMotDePasse}">cliquer ici</a></span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "modaliteBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'montant participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{montantParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'indication participation',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{indicationParticipation}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prélude adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{preludeAdresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'date événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{dateEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'type événement',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{typeEvenement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'theme',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{theme}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      } else if (tab.key == "ResponseBloc") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse accepter',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseAccepte}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse refuser',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{urlReponseDecline}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse transférer',
                                                                  insert: '<span><a href="{urlTransfertInvitation}">cliquer ici</a></span>'
                                                              }
                                                          ]
                                                      } else if (tab.key == "mailFooters") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom/nom responsable agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomResponsableAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'email agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{emailAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'téléphone agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{telAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'adresse agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{adresseAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'code postal agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{codePostalAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ville agence',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{villeAgence}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Nom agence',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                                                              },
                                                          ]
                                                      }
                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={4}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsStripe.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={(e) => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }


                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={5}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsFacturation.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }


                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey={6}>
                            <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>
                                {tabsForum.map(tab =>
                                    <Nav.Item key={tab.key} className='d-flex mb-2'>
                                        <Nav.Link as={Button}
                                                  onClick={() => {
                                                      if (currentParam) {
                                                          BtSwal.fire(
                                                              {
                                                                  title: "Voulez-vous changer de paramètres ?",
                                                                  text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",
                                                                  showDenyButton: true,
                                                                  confirmButtonText: `Oui`,
                                                                  denyButtonText: `Annuler`,
                                                                  icon: 'warning',
                                                              }).then((result) => {
                                                              if (result.isConfirmed){
                                                                  setCurrentParam(tab)
                                                              }
                                                          })
                                                      }else {
                                                          setCurrentParam(tab)
                                                      }

                                                      if (tab.key == "mailNotifForums") {
                                                          motsClesProxy.motsCles = [
                                                              {
                                                                  type: 'menuitem',
                                                                  text: '',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Catégorie du sujet',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{categorieSujet}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Objet du sujet',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{objetSujet}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Corps du sujet',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{corpsSujet}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'Prénom et nom de l\'auteur',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenomNomAuteur}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'prenom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'nom',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'civilite',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'te/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'toi/vous',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ta/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'ton/votre',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 'tes/vos',
                                                                  insert: '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                                                              },
                                                              {
                                                                  type: 'menuitem',
                                                                  text: 't\'/vous',
                                                                  insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                                                              }
                                                          ]
                                                      }


                                                  }}
                                                  variant='white'
                                                  eventKey={tab.key}
                                                  className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'
                                        >
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Accordion.Collapse>
                        {/*<Accordion.Collapse eventKey={7}>*/}
                        {/*    <Nav variant='pills' className='bg-light rounded p-5 border min-w-250px filter'>*/}
                        {/*        {tabsReseauxSociaux.map(tab =>*/}
                        {/*            <Nav.Item key={tab.key} className='d-flex mb-2'>*/}
                        {/*                <Nav.Link as={Button}*/}
                        {/*                          onClick={() => {*/}
                        {/*                              if (currentParam) {*/}
                        {/*                                  BtSwal.fire(*/}
                        {/*                                      {*/}
                        {/*                                          title: "Voulez-vous changer de paramètres ?",*/}
                        {/*                                          text : "Si vous quittez cette page, les modifications non enregistrées seront perdues.",*/}
                        {/*                                          showDenyButton: true,*/}
                        {/*                                          confirmButtonText: `Oui`,*/}
                        {/*                                          denyButtonText: `Annuler`,*/}
                        {/*                                          icon: 'warning',*/}
                        {/*                                      }).then((result) => {*/}
                        {/*                                      if (result.isConfirmed){*/}
                        {/*                                          setCurrentParam(tab)*/}
                        {/*                                      }*/}
                        {/*                                  })*/}
                        {/*                              }else {*/}
                        {/*                                  setCurrentParam(tab)*/}
                        {/*                              }*/}


                        {/*                          }}*/}
                        {/*                          variant='white'*/}
                        {/*                          eventKey={tab.key}*/}
                        {/*                          className='mx-3 my-1 p-3 fs-5 fw-bolder text-start w-100 btn-active-light-primary'*/}
                        {/*                >*/}
                        {/*                    {tab.name}*/}
                        {/*                </Nav.Link>*/}
                        {/*            </Nav.Item>*/}
                        {/*        )}*/}
                        {/*    </Nav>*/}
                        {/*</Accordion.Collapse>*/}
                        <div className='separator row my-10 d-md-none'/>
                        <Tab.Content style={{zIndex : 10}} className='w-100 mt-5'>
                            {currentParam.component}
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </Accordion>
        </Card.Body>
    </Card>;
}

export default ParametresView;