import { proxy } from "valtio";

const speedBusinessProxy = proxy({
    showEditSpeedBusinessModal: false,
    showFiltresParticipants:false,
    showJourJModal: false,
    speedBusiness:null,
    destinataireIsChecked:false,
    destinataireAlertIsAdd: false,
    step: 0,
    loading: false,
    idInvitation:null,
    websocket: {
        send : (cache) => {
            console.log("ws hs")
        },
        sendParticipant : (cache) => {
            console.log("ws hs")
        },
    },
    objectCurrentPresentationSpeed: null,
    tabParticipantArrive: [],
    tabParticipantArriveTable: [],
    updateParticipantsArrive:0,
    diffServeurTimestamp: null,
    showEditSpeedBusiness:(speedBusiness=null, idInvitation = null) => {
        speedBusinessProxy.showEditSpeedBusinessModal = true;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.idInvitation = idInvitation;
        speedBusinessProxy.step = 0;
    },
    showJourJ:(speedBusiness) => {
        speedBusinessProxy.showJourJModal = true;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.step = 0;
    },
});

export default speedBusinessProxy;