import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import React from "react";
import Select2 from "../../../components/Select2/Select2";

export default function FiltresModal({tabEtatsInvitationSelect, stateIdTabInvitationFilter, tabPaiementSelect, stateIdEtatPaiement, data, stateIdOptionParticipation}){
    const speedSnap = useSnapshot(speedBusinessProxy);


    return <Modal
        show={speedSnap.showFiltresParticipants}
        onHide={() => speedBusinessProxy.showFiltresParticipants = false}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton><h2>Filtres</h2></Modal.Header>
        <Modal.Body>
            <div>
                <h4>Réponses</h4>
                <Select2
                    data={tabEtatsInvitationSelect}
                    closeOnSelect={true}
                    allowClear={true}
                    placeholder='Filtre réponse invitation'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        stateIdTabInvitationFilter(selected)
                    }}/>
            </div>
            <div className="mt-5">
                <h4>Paiements</h4>
                <Select2

                    data={tabPaiementSelect}
                    closeOnSelect={true}
                    allowClear={true}
                    placeholder='Filtre paiement'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        stateIdEtatPaiement(selected)
                    }}/>
            </div>
            <div className="mt-5">
                <h4>Option choisie</h4>
                <Select2
                    data={data}
                    closeOnSelect={true}
                    allowClear={true}
                    placeholder='Filtre option'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        stateIdOptionParticipation(selected)
                    }}/>
            </div>

        </Modal.Body>
    </Modal>
}