import LoadingView from "../../../LoadingView/LoadingView";
import useRepondreInvitationByMail from "../../../../requests/communication/invitations/useRepondreInvitationByMail";
import AccepteView from "./AccepteView";
import DeclineView from "./DeclineView";
import {useSnapshot} from "valtio";
import auth from "../../../../services/auth";
import {baseURL} from "../../../../config";
import React from "react";
import {renderSwitchInscriptionInscriptionLibreEtPreview} from "../../../../views/communication/InvitationsView/functions";

function ResponseView() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const relance = urlParams.get('relance') ? urlParams.get('relance').replace(/ /g, "+") : "";
    const repondreInvitationByMail = useRepondreInvitationByMail(ref);
    const snapAuth = useSnapshot(auth);

    if (repondreInvitationByMail.isError || ref === ""){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Répondre à l'invitation</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <p>{repondreInvitationByMail.error?.code == 402 ? "Le paiement est momentanément indisponible. Vous pouvez contacter le responsable de l'agence "+repondreInvitationByMail.error?.data?.prenomNomResponsable + " par mail : "+ (repondreInvitationByMail.error?.data?.email ? repondreInvitationByMail.error?.data?.email : "non communiqué") + " ou par téléphone : " + (repondreInvitationByMail.error?.data?.tel ? repondreInvitationByMail.error?.data?.tel : "non communiqué") : repondreInvitationByMail.error?.message} </p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!repondreInvitationByMail.isSuccess){
        return <LoadingView/>
    }

    const convertTabOptionsParticipation = repondreInvitationByMail.data.invitation?.tabOptionsParticipation ? Object.entries(repondreInvitationByMail.data.invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];



    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                    {(!repondreInvitationByMail.data.correspondantInvitation?.tabPaiements[0]?.actif && !repondreInvitationByMail.data.correspondantInvitation?.tabPaiements[2]?.actif)
                        ?
                        <>
                            <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Votre présence a déjà été
                                confirmée</h1>
                            <p className="text-white">{repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.civilite} {repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.prenom} {repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.nom},
                                nous vous
                                attendons {repondreInvitationByMail.data.invitation.type?.motDeLiaison} {repondreInvitationByMail.data.invitation.type?.libelle}
                                <strong>{repondreInvitationByMail.data.invitation.dateEvenementFormatee.toLowerCase()}</strong>. {convertTabOptionsParticipation.length > 0 ? "(option choisie :" + repondreInvitationByMail.data.correspondantInvitation?.optionParticipationChoisie + ")" : ""}
                                {repondreInvitationByMail.data.correspondantInvitation?.tabPaiements[1]?.actif && <>
                                    &nbsp; voici les justificatifs du paiement :
                                    <span
                                        onClick={() => {
                                            window.open(baseURL + "/stripe/recu?referenceCharge=" + repondreInvitationByMail.data.correspondantInvitation?.referenceCharge, "_blank")
                                        }}
                                        className="me-2 cursor-pointer"><i style={{fontSize: "25px"}}
                                                                           className="fas fa-receipt text-center ms-2"></i></span>
                                    <span
                                        className="me-2 cursor-pointer"
                                        onClick={() => {
                                            window.open(baseURL + "/facture/afficher?referencePaiement=" + repondreInvitationByMail.data.correspondantInvitation?.referencePaiement, "_blank")
                                        }}
                                    ><i style={{fontSize: "25px"}}
                                        className="fas fa-file-invoice text-center"></i></span>
                                </>
                                } </p>
                        </>
                        :
                        <>{repondreInvitationByMail.data.correspondantInvitation.tabEtatsInvitation[2].actif ?
                            <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Nous sommes ravis de votre
                                choix</h1> :
                            <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Nous sommes navrés de votre choix.
                                Peut-être à une autre fois.</h1>}
                          {!repondreInvitationByMail.data.correspondantInvitation.tabEtatsInvitation[3].actif && <><p className="text-white">{repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.civilite} {repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.prenom} {repondreInvitationByMail.data.correspondantInvitation?.destinataire?.personne?.nom},
                                vous validez <strong>votre
                                    présence</strong> {repondreInvitationByMail.data.invitation.type?.motDeLiaison} {repondreInvitationByMail.data.invitation.type?.libelle} du
                                <strong> {repondreInvitationByMail.data.invitation.dateEvenementFormatee.toLowerCase().substring(3)}</strong>.
                            </p>
                            <div className=''>
                                {renderSwitchInscriptionInscriptionLibreEtPreview(repondreInvitationByMail.data.invitation.blocsPersonnalisesPageReponse, "champs1", "#fff")}
                            </div> </>}
                        </>}

                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
            <div className=''>
                <div className=''>
                {repondreInvitationByMail.data.correspondantInvitation.tabEtatsInvitation[2].actif ? <AccepteView
                        dataCorrespondant={repondreInvitationByMail.data.correspondantInvitation}
                        dataInvitation={repondreInvitationByMail.data.invitation}
                        dataAccompagnateur={repondreInvitationByMail.data.accompagnateur}
                        isRelance={relance == "true"}
                    /> : <DeclineView
                        dataInvitation={repondreInvitationByMail.data.invitation}
                        dataCorrespondant={repondreInvitationByMail.data.correspondantInvitation}
                    />}
                </div>
            </div>
        </div>
    </div>;



}

export default ResponseView;
