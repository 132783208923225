import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import {Navigate, useNavigate} from "react-router";
import LoadingButton from "@components/LoadingButton/LoadingButton";
import {useParams} from "react-router-dom";
import useLogin from "../../../requests/utilisateur/useLogin";

function LoginForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const {agence} = useParams();
    const login = useLogin();

    function onSubmit(data) {
        login.mutate(data);
    }

    if (login.isSuccess){
        navigate("/"+login?.data?.agence?.dossierUpload+"/accueil");
        if (agence != login?.data?.agence?.dossierUpload){
            window.location.reload();
        }
    }


    return <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit(onSubmit)} >
        <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Connectez vous</h1>
            <div className='text-gray-400 fw-bold fs-4'>pour accéder à votre espace</div>
        </div>
        <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label fs-6 fw-bolder text-dark'>Identifiant</label>
            <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                name='email'
                autoComplete='off'
                {...register('identifiant', { required: true })}
            />
            {errors.identifiant &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
        </div>
        <div className='fv-row mb-10 fv-plugins-icon-container'>
            <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
            </div>
            <input
                className='form-control form-control-lg form-control-solid'
                type='password'
                name='password'
                autoComplete='off'
                {...register('motDePasse', { required: true })}
            />
            {errors.motDePasse &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
        </div>
        <div className='text-center'>
            <LoadingButton
                loading={login.isLoading}
                type='submit'
            >Se connecter</LoadingButton>
            {/*{login.isSuccess && <Navigate to={"/"+login?.data?.agence?.dossierUpload+"/accueil"} replace={true} />}*/}
        </div>
        <Button
            onClick={() => navigate("/"+(agence ? agence : "plink")+"/sendResetPassword")}
            variant='link' className='m-auto w-100'>Mot de passe oublié ?</Button>
    </form>;
}

export default LoginForm;