import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetQueryParticipants(id, filterCol1, filterCol2, key, filterCol3 = null) {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = filterCol1;
    filters.columns[1] = filterCol2;
    if (filterCol3){
        filters.columns[3] = filterCol3;
    }
    filters.columns[2] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        },
    }

    filters.order[0] = {
        column:2,
        dir: "asc"
    }


    return useQuery(
        ['participants', id, key],
        async () => {
            return api.get(`rencontreSpeedBusiness/${id}/participants`, {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 1000,
                    ...filters
                }, { arrayFormat: 'brackets' }),
            }).json();},
        {
            enabled: id != null,
            },
        );
}