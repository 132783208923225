import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useValidPresence() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(validPresence, {
        onSuccess: (res) => {
            queryClient.invalidateQueries('rencontreSpeedBusiness');
            queryClient.invalidateQueries('presentEtEnRetard');
            queryClient.invalidateQueries('participants');
            snapAuth.websocket.send("rencontreSpeedBusiness")

            // queryClient.setQueryData('invitations', res);
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function validPresence(rencontreSpeedBusiness) {
    //id -> id participant
    const id = rencontreSpeedBusiness.id;

    if (id != null)
        return api.post(`participantRencontreSpeedBusiness/${id}/ValiderPresence`, { json: {
            idEtatPresence: rencontreSpeedBusiness.idEtatPresence
            } }).json();
}