import {faCheck, faFileDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Form, Modal} from "react-bootstrap";
import Select2 from "@components/Select2/Select2";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import React, {useEffect, useMemo, useRef, useState} from "react";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useChangeCorrespondant from "../../../../requests/communication/correspondant/useChangeCorrespondant";
import toast from "react-hot-toast";
import {count} from "react-table/src/aggregations";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import useResendInvitationForOneCorrespondant
    from "../../../../requests/communication/invitations/useResendInvitationForOneCorrespondant";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import useSendRelancePaiement from "../../../../requests/communication/invitations/useSendRelancePaiement";
import useSendJustificatifsPaiement from "../../../../requests/communication/invitations/useSendJustificatifsPaiement";
import auth from "../../../../services/auth";
import InfoPersonneForSuiviModal from "./InfoPersonneForSuiviModal";
import ExportSuiviModal from "./ExportSuiviModal";
import useRelancePaiement from "../../../../requests/communication/invitations/useRelancePaiement";
import moment from "moment/moment";
import useGetChampPerso from "../../../../requests/annuaire/useGetChampPerso";
import useValidPresenceInvitation from "../../../../requests/communication/invitations/useValidPresenceInvitation";
const $ = require('jquery');



function SuiviModal({ onHide, invitation }) {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const validerPresence = useValidPresenceInvitation();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const [showInformationComplementaireReponse, setShowInformationComplementaireReponse] = useState({show : false, data : ""});
    const queryClient = useQueryClient();
    const resendForOneCorrepondant = useResendInvitationForOneCorrespondant();
    const sendJustificatifsPaiement = useSendJustificatifsPaiement();
    const snapAuth = useSnapshot(auth);
    const correspondant = useChangeCorrespondant({
        onSuccess: () => {
            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants']);
            snapAuth.websocket.send("invitation")
            snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
            snapAuth.websocket.send("correspondants")

            toast.success('Réponse modifié', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const champsPerso = useGetChampPerso("");

    const [idTabInvitationFilter, stateIdTabInvitationFilter] = useState("");
    const [idEtatPaiement, stateIdEtatPaiement] = useState("");
    const [idEtatPresence, stateIdEtatPresence] = useState("");
    const [idOptionParticipation, stateIdOptionParticipation] = useState("");
    const [prenomNomTabAccompagnants, setPrenomNomTabAccompagnants] = useState("");
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");

    useEffect(() => {
        stateIdTabInvitationFilter("")
        stateIdEtatPaiement("")
        stateIdEtatPresence("")
        stateIdOptionParticipation("")
        setPrenomNomTabAccompagnants("")
        setPrenom("")
        setPrenomNom("")
        setNom("")
    }, [invitationsSnapshot.suiviModal.show]);
    const sendRelancePaiement = useSendRelancePaiement();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY HH:mm"));

    const sendRelancePaiementAll = useRelancePaiement({
        id: invitationsSnapshot.invitation?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail de relance de paiement a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const colSearch = useMemo(() => {
        return {
            'etatInvitation': idTabInvitationFilter,
            'etatPresence': idEtatPresence,
            'etatPaiement': idEtatPaiement,
            'optionParticipation.id': idOptionParticipation,
            'tabAccompagnants.destinataire.personne.prenomNom': prenomNomTabAccompagnants,
            'destinataire.personne.prenom': prenom,
            'destinataire.personne.nom': nom,
            'destinataire.personne.prenomNom': prenomNom,
        };
    }, [idTabInvitationFilter, idEtatPresence, idEtatPaiement, idOptionParticipation, prenomNomTabAccompagnants, prenom, nom, prenomNom]);

    const tableRef = useRef();

    const convertTabOptionsParticipation = invitationsSnapshot.invitation?.tabOptionsParticipation ? Object.entries(invitationsSnapshot.invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];

    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [invitationsSnapshot.invitation?.id, invitationsSnapshot.invitation?.tabOptionsParticipation]);

    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'destinataire.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPaiement',
                accessor: 'etatPaiement',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true
            },
            {
                Header: 'optionParticipation',
                accessor: 'optionParticipation.id',
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'destinataire.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'destinataire.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'destinataire.personne.prenom',
                hidden: true,
            },
            // {
            //     Header: 'nom',
            //     accessor: 'destinataire.personne.nom',
            //     hidden: true,
            // },
            {
                Header: 'Destinataire',
                accessor: 'destinataire.personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.destinataire.personne.prenom + " " + row.original.destinataire.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original.destinataire);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: ({row, label}) => (
                    <>
                        <span className="me-2">Réponse invitation</span>
                    </>
                ),
                id: 'reponseInvitation',
                accessor: 'tabEtatsInvitation',
                minWidth: 300,
                maxWidth: 300,
                Cell: ({row}) => {
                    const etatsInvitation = (row.original.tabEtatsInvitation).map((value, index) => {

                            return <label
                                key={index}
                                className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.actif ? `active` : ``}`}
                                data-kt-button="true">
                                <input
                                    onClick={(err) => {
                                        if (value.libelle == "Accepte") {
                                            window.open(row.original.urlAccepte, "_blank")
                                        } else if (value.id == 3) {
                                            window.open(row.original.urlAccepte + "&relance=true", "_blank")
                                        } else {
                                            let newCorrespondantReponse = row.original;
                                            newCorrespondantReponse.tabEtatsInvitation.forEach(item => {
                                                item.actif = item.id === value.id;
                                            })

                                            correspondant.mutate(newCorrespondantReponse)
                                            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                            snapAuth.websocket.send("invitation")
                                            snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                            snapAuth.websocket.send("correspondants")
                                        }
                                    }}
                                    className="btn-check" type="radio" name="method" value={value.id}/>
                                <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                        }
                    );

                    const etatsInvitationDisabled = (row.original.tabEtatsInvitation).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    return <div>
                        <div className="text-center">
                            <div className="btn-group" data-kt-buttons="true"
                                 data-kt-buttons-target="[data-kt-button]">
                                {row.original.tabPaiements[1].actif ? etatsInvitationDisabled : etatsInvitation}
                            </div>
                        </div>
                    </div>
                },
                Footer: info => {
                    let tabEtatsInvitationSelect = [
                        {id: 0, text: "En attente"},
                        {id: 3, text: "Paiement en cours"},
                        {id: 1, text: "Accepte"},
                        {id: 2, text: "Décline"},
                    ]
                    return <Select2
                        data={tabEtatsInvitationSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre réponse invitation'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdTabInvitationFilter(selected)
                        }}/>
                },
            },
            {
                Header: ({row}) => {
                    return <div className="d-inline">
                        <span className="me-2">Paiement</span>
                        {/*<span*/}
                        {/*    onClick={() => {*/}
                        {/*        BtSwal.fire(*/}
                        {/*            {*/}
                        {/*                title: "Voulez-vous envoyer une relance de paiement pour tous ceux qui n'ont pas finaliser leur inscription ?",*/}
                        {/*                showDenyButton: true,*/}
                        {/*                confirmButtonText: `Oui`,*/}
                        {/*                denyButtonText: `Annuler`,*/}
                        {/*                icon: 'info',*/}
                        {/*            }).then((result) => {*/}
                        {/*            if (result.isConfirmed){*/}
                        {/*                sendRelancePaiementAll.mutate(row?.original?.id)*/}
                        {/*            }*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*    title="Relancer tous les paiements en attente"*/}
                        {/*    className='svg-icon svg-icon-1 me-2 text-center cursor-pointer mt-2'><img width={30} src="/icon/money-bill-transfer.svg" alt="bell"/></span>*/}
                    </div>
                },
                id: 'Paiement',
                accessor: 'tabPaiements',
                minWidth: 180,
                maxWidth: 180,
                noWrap: true,
                displayFlex: true,
                Cell: ({row}) => {
                    let result = [];

                    row.original.tabPaiements.forEach((value, index) => {
                        if (value.actif) {
                            result.push(<span>{value.libelle}</span>)
                            //si le paiement est confimé
                            if ((value.id == 0 && !row?.original?.idPersonneAccompagnateur) || (value.id == 0 && row?.original?.idPersonneAccompagnateur && row?.original?.paieSaPart)) {
                                result.push(<span
                                    style={{fill: "#211f1c"}}
                                    title="Envoyer les justificatifs de paiement"
                                    onClick={() => {
                                        sendJustificatifsPaiement.mutate(row.original.id)
                                    }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><img width={25}
                                                                                                src="/icon/envelope-open-dollar.svg"
                                                                                                alt="bell"/></span>)
                            } else if (row?.original?.idPersonneAccompagnateur && !row?.original?.paieSaPart) {
                                result = "Pris en charge par l'accompagnant"
                            }
                        }
                    });

                    return result
                },
                Footer: info => {
                    let tabPaiementSelect = [
                        {id: "NULL", text: "Sans paiement"},
                        {id: 0, text: "Carte bancaire Confirmé"},
                        {id: 1, text: "Carte bancaire en attente de confirmation"},
                        {id: 2, text: "Sur place"},
                        {id: 3, text: "Gratuit"},
                    ]
                    return <Select2

                        data={tabPaiementSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre paiement'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPaiement(selected)
                        }}/>
                },
            },
            {
                Header: 'Infos complémentaires',
                accessor: 'informationComplementaireReponse',
                minWidth: 230,
                Cell: ({row}) => {
                    if (row.original.informationComplementaireReponse) {
                        return <>
                            <Button
                                onClick={() => {
                                    setShowInformationComplementaireReponse({
                                        ...showInformationComplementaireReponse,
                                        show: true,
                                        data: row.original.informationComplementaireReponse
                                    })
                                }}
                                variant={"primary"}>
                                Voir les informations <i className="fa fa-eye"/>
                            </Button>
                        </>
                    } else {
                        return "";
                    }
                }
            },
            {
                Header: 'Présence évènement',
                id: 'presenceEvenement',
                accessor: 'tabEtatsPresence',
                minWidth: 240,
                maxWidth: 240,
                Cell: ({row}) => {
                    const etatsPresence = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {

                                    let newCorrespondantPresence = row.original;
                                    newCorrespondantPresence.tabEtatsPresence.forEach(item => {
                                        item.actif = item.id === value.id;
                                    })

                                    // correspondant.mutate(newCorrespondantPresence)
                                    validerPresence.mutate({
                                        id: row.original?.id,
                                        idEtatPresence: value?.id
                                    })
                                    queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                    snapAuth.websocket.send("invitation")
                                    snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                    snapAuth.websocket.send("correspondants")

                                }}
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    const etatsPresenceDisabled = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    return <div className="btn-group" data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]">
                        {row.original.tabEtatsInvitation[2].actif ? etatsPresence : etatsPresenceDisabled}
                    </div>
                },
                Footer: info => {
                    let tabEtatsPresenceSelect = [
                        {id: 0, text: "En attente"},
                        {id: 1, text: "Présent"},
                        {id: 2, text: "Absent"},
                        {id: 3, text: "Excusé"},
                    ]
                    return <Select2
                        data={tabEtatsPresenceSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre présence'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPresence(selected)
                        }}/>
                },
            },
            {
                Header: 'Option choisie',
                id: 'optionChoisie',
                accessor: 'optionParticipation',
                minWidth: 200,
                Cell: ({row}) => {
                    let tmpData = [...data];
                    if (row.original.tabPaiements[1].actif){
                        if (!tmpData.find(item => item.id == row.original.optionParticipation?.id)){
                            tmpData.push({
                                id: row.original.optionParticipation?.id,
                                text: `${row.original.optionParticipation.designation} -- ${row.original.optionParticipation.montant} €TTC`,
                            })
                        }
                    }
                    const tabOptions = <Select2 solid
                                                data={data}
                                                selected={row.original.optionParticipation.id != null ? [row.original.optionParticipation.id.toString()] : null}
                                                placeholder='Sélectionner un expéditeur'
                                                onChange={(selected) => {
                                                    selected = selected?.at(0);
                                                    const res = convertTabOptionsParticipation.find(
                                                        exp => exp.id.toString() === selected
                                                    );
                                                    row.original.optionParticipation = res;
                                                    correspondant.mutateAsync(row.original)
                                                    queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                                    snapAuth.websocket.send("invitation")
                                                    snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                                    snapAuth.websocket.send("correspondants")
                                                }}
                    />

                    const tabOptionsDisabled = <Select2 solid
                                                        disabled={true}
                                                        data={tmpData}
                                                        selected={row.original.optionParticipation.id != null ? [row.original.optionParticipation.id.toString()] : null}
                                                        placeholder='Sélectionner un expéditeur'
                                                        onChange={(selected) => {
                                                            selected = selected?.at(0);
                                                            const res = convertTabOptionsParticipation.find(
                                                                exp => exp.id.toString() === selected
                                                            );
                                                            row.original.optionParticipation = res;
                                                            correspondant.mutateAsync(row.original)
                                                            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants'])
                                                            snapAuth.websocket.send("invitation")
                                                            snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
                                                            snapAuth.websocket.send("correspondants")
                                                        }}
                    />

                    return row.original.tabPaiements[1].actif ? tabOptionsDisabled : tabOptions
                },
                Footer: info => {
                    return <Select2
                        data={data}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre option'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdOptionParticipation(selected)
                        }}/>
                },
            },
            {
                Header: 'Accompagnants',
                id: 'idTabAccompagnants',
                orderable: false,
                minWidth: 200,
                Cell: ({row}) => {
                    let tabAccompagnants = "";
                    if (row.original.tabAccompagnants) {
                        tabAccompagnants = (Object.values(row.original.tabAccompagnants)).map((value, index) =>
                            <><a key={index} target="_blank" className={`color-primary invites${row.original.id}`}
                                 href={"mailto:" + value.destinataire?.personne?.email}
                                 rel="noreferrer">{value.destinataire?.personne?.prenom} {value.destinataire?.personne?.nom}<br/></a> </>
                        )
                    }

                    let result = "";

                    if (tabAccompagnants !== "" && count(Object.values(row.original.tabAccompagnants)) > 1) {
                        result =
                            <div className="d-flex justify-content-center"><Button id={"showInvite" + row.original.id}
                                                                                   onClick={() => {
                                                                                       if ($(".invites" + row.original.id).hasClass("d-none")) {
                                                                                           $(".invites" + row.original.id).removeClass("d-none");
                                                                                           $("#showInvite" + row.original.id).text("Cacher")
                                                                                       } else {
                                                                                           $(".invites" + row.original.id).addClass("d-none");
                                                                                           $("#showInvite" + row.original.id).text("Voir")
                                                                                       }
                                                                                   }
                                                                                   }>Voir</Button></div>
                        $(".invites" + row.original.id).addClass("d-none");
                    }

                    return <span>{tabAccompagnants} <br/> {result} </span>;

                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNomTabAccompagnants(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher accompagnant'
                    />
                },
            },
            {
                Header: 'État',
                id: 'etat',
                accessor: 'etatSuivi',
                orderable: false,
                minWidth: 380,
                Cell: ({row}) => {
                    let result = [<span className='text-info'>En attente d'envoi</span>];
                    if (row.original.destinataire?.personne?.email == null)
                        return <span className='text-warning'>Adresse mail manquante</span>;
                    if (row.original.destinataire?.personne?.mailIsDesinscrit)
                        return <span className='text-danger'>Ne souhaite plus recevoir de mail</span>;
                    if (row.original.destinataire?.personne?.mailIsErrone)
                        return <span className='text-danger'>Adresse mail erronée</span>;
                    if (row.original.dateEnvoiInvitation !== null) {
                        result = []
                        if (row.original.envoiInvitationIsFail) {
                            result.push([<span
                                className='text-danger'>L'envoi à échoué le {row.original.dateEnvoiInvitation}<FontAwesomeIcon
                                icon={faTimes} className='ms-2'/></span>])
                        } else {
                            result.push([<span
                                className='text-success'>Invitation envoyé le {row.original.dateEnvoiInvitation}<FontAwesomeIcon
                                icon={faCheck} className='ms-2'/></span>]);
                        }
                    }
                    if (row.original.dateEnvoiRelance !== null) {
                        if (row.original.envoiRelanceIsFail) {
                            result.push(<br/>);
                            result.push(<span
                                className='text-danger'>L'envoi de la relance à échoué le {row.original.dateEnvoiRelance}<FontAwesomeIcon
                                icon={faTimes} className='ms-2'/></span>);
                        } else {
                            result.push(<br/>);
                            result.push(<span className='text-info'>Relance envoyé le {row.original.dateEnvoiRelance}
                                <FontAwesomeIcon icon={faCheck} className='ms-2'/></span>);
                        }
                    }
                    if (row.original.dateEnvoiRappel !== null) {
                        if (row.original.envoiRappelIsFail) {
                            result.push(<br/>);
                            result.push(<span
                                className='text-danger'>L'envoi du rappel à échoué le {row.original.dateEnvoiRappel}<FontAwesomeIcon
                                icon={faTimes} className='ms-2'/></span>);
                        } else {
                            result.push(<br/>);
                            result.push(<span className='text-info'>Rappel envoyé le {row.original.dateEnvoiRappel}
                                <FontAwesomeIcon icon={faCheck} className='ms-2'/></span>);
                        }
                    }

                    result.push(<br/>);
                    result.push([<div onClick={() => {
                        invitationsSnapshot.showDetailInvitationModal(row.original)
                    }}
                                      className="m-auto text-center btn p-2 m-0 cursor-pointer me-5 btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success"
                                      style={{width: "fit-content", fontSize: 10}}>Détails de l'envoi</div>, <span
                        onClick={() => {
                            resendForOneCorrepondant.mutate(row.original.id)
                        }}
                        title="Renvoyer l'invitation"
                        className='svg-icon svg-icon-1 me-2 text-center cursor-pointer mt-2'><img width={20}
                                                                                                  src="/icon/repeat.svg"
                                                                                                  alt="bell"/></span>])

                    return result
                }
            },
            {
                Header: 'Statistiques',
                id: 'stats',
                orderable: false,
                minWidth: 150,
                Cell: ({row}) => {
                    return <div className="d-flex justify-content-center align-items-center"><img style={{width: 30}}
                                                                                                  src="/icon/envelope-open-text.svg"
                                                                                                  className="me-2"
                                                                                                  alt="nombre ouvertures"/> {row.original.nbOuverturesInvitation > 0 ? row.original.nbOuverturesInvitation : 0}
                        <img className="ms-2 me-2" width={30} src="/icon/click.svg"
                             alt="clickimg"/> {row.original.nbClicsInvitation > 0 ? row.original.nbClicsInvitation : 0}
                    </div>
                }
            }
        ]


        let tmpChampsPerso = champsPerso.data?.data.map(exp => {
            if (exp?.parametre?.isAfficheSuivi){
                return  {
                    Header: exp?.libelle,
                    id: exp?.libelle+exp.id,
                    minWidth: 100,
                    Cell:({value, row}) => {
                        if(row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire && row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire[exp.id]){
                            return row?.original?.destinataire?.personne?.tabChampsPersonnalisesAnnuaire[exp.id].valeur;
                        }else {
                            return "";
                        }
                    },
                }
            }else {
                return null
            }
        });
        if (tmpChampsPerso){
            tmpChampsPerso = tmpChampsPerso.filter(e => e != null)
            return [
                ...tmp.slice(0, 11),
                ...tmpChampsPerso,
                ...tmp.slice(11),
            ]
        }else {
            return tmp;
        }
    }, [data]);



    return <Modal
        show={invitationsSnapshot.suiviModal.show}
        onHide={() => invitationsProxy.suiviModal.show = false}
        fullscreen
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Suivi de l'invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <h3>Liste des destinataires</h3>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url={`invitation/${invitationsSnapshot.invitation?.id}/correspondants`}
                    keys={['invitation', invitationsSnapshot.invitation?.id, 'correspondants']}
                    columns={columns}
                    search={debouncedSearch}
                    colSearch={colSearch}
                    showFiltres={true}
                />
            </div>
            <div className='d-flex mt-3'>
                <Button
                    variant='secondary'
                    className='ms-auto'
                    onClick={() => {
                        // tableRef.current?.export();
                        invitationsSnapshot.showExportSuiviModal();
                    }}
                >
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                    Exporter
                </Button>
            </div>
            <InfoPersonneForSuiviModal/>
            <ExportSuiviModal/>
            <Modal
                show={showInformationComplementaireReponse.show}
                onHide={() => {
                    setShowInformationComplementaireReponse({
                        ...showInformationComplementaireReponse,
                        show: false
                    })
                }}
                size='lg'
            >
                <Modal.Body>
                    {showInformationComplementaireReponse.data}
                </Modal.Body>
            </Modal>
        </Modal.Body>
    </Modal>;
}

export default SuiviModal;