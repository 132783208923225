import TableChangementTable from "./TableChangementTable";
import useGetQueryGenererParTable from "../../../../requests/communication/speedBusiness/useGetQueryGenererParTable";
import LoadingView from "../../../../views/LoadingView/LoadingView";
import useGetQueryGenererParParticipant
    from "../../../../requests/communication/speedBusiness/useGetQueryGenererParParticipant";

export default function PlacementChangementTable({speed, nbTablesTotales, arrayDataParticipantMaitre, arrayDataParticipantMaitreSuppleant, arrayDataParticipantSansMaitre, indexTour}){

    const placements = useGetQueryGenererParTable(speed?.id);
    const placementParPersonne = useGetQueryGenererParParticipant(speed?.id);
    //todo rajouter la gestion des maitres suppléant -> fait !
    const mapTables = () => {
        if (placements?.data){
            let tmpMap = [];
            for (let i = 0; i < nbTablesTotales; i++) {
                tmpMap.push(<span className={`${nbTablesTotales > 48 ? "pe-1" : "pe-1"} pb-2`}><TableChangementTable indexTour={indexTour} nomTables={speed?.tabNomsTables} nomTable={speed?.tabNomsTables[i]} arrayDataParticipantSansMaitre={arrayDataParticipantSansMaitre} arrayDataParticipantMaitre={arrayDataParticipantMaitre} placementParPersonne={placementParPersonne?.data} arrayDataParticipantMaitreSuppleant={arrayDataParticipantMaitreSuppleant} indexParticipants={Object.values(Object.values(placements?.data)[i])[indexTour]} nbTablesTotales={nbTablesTotales} nbTours={speed?.nombreTours}/> </span>)
            }
            return tmpMap;
        }else {
            return [];
        }
    }

    if (placements?.isLoading || placementParPersonne?.isLoading){
        return <LoadingView/>
    }
    
     return <div className="d-flex flex-wrap justify-content-around w-100 " style={{
         marginLeft: 10
     }}>
         {mapTables()}
     </div>
}