import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangeIsMaitreDuTempsSuppleant() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(isMaitreDuTempsSuppleant, {
        onSuccess: (res) => {
            queryClient.invalidateQueries('rencontreSpeedBusiness');
            snapAuth.websocket.send("rencontreSpeedBusiness")

            // queryClient.setQueryData('invitations', res);
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function isMaitreDuTempsSuppleant(rencontreSpeedBusiness) {
    const id = rencontreSpeedBusiness.id;

    if (id != null)
        return api.post(`participantRencontreSpeedBusiness/${id}/isMaitreDuTempsSuppleant`, { json: {
                isMaitreDuTempsSuppleant:rencontreSpeedBusiness.isMaitreDuTempsSuppleant
            } }).json();
}