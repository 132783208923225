import React, {forwardRef, useMemo, useRef, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import auth from "../../../../services/auth";
import annuaireProxy from "../../../../proxies/annuaire";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import useResendMailOrdreForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendMailOrdreForOneCorrespondant";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import useResendMailMaitreDuTempsForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendMailMaitreDuTempsForOneCorrespondant";
import useResendSmsOdreForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendSmsOdreForOneCorrespondant";
import useResendSmsMaitreDuTempsForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendSmsMaitreDuTempsForOneCorrespondant";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {French} from "flatpickr/dist/l10n/fr";
import useSendMailsRencontre from "../../../../requests/communication/speedBusiness/useSendMailsRencontre";

const Lancement = forwardRef((_, ref) => {

    const speedSnap = useSnapshot(speedBusinessProxy);
    const snapAuth = useSnapshot(auth);
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const sendMailOrdreOneCorrespondant = useResendMailOrdreForOneCorrespondant();
    const sendSmsOrdreOneCorrespondant = useResendSmsOdreForOneCorrespondant();
    const sendMailMaitreOneCorrespondant = useResendMailMaitreDuTempsForOneCorrespondant();
    const sendSmsMaitreOneCorrespondant = useResendSmsMaitreDuTempsForOneCorrespondant();
    const [debouncedSearch] = useDebounce(search, 300);
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");
    const colSearch = useMemo(() => {
        return {
            'etatPresence': '0,2',
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
        };
    }, [prenom, nom, prenomNom]);
    const sendMailRappel = useSendMailsRencontre();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY à HH:mm"));

    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                searchOperator:"IN",
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 300,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<div class="me-7">
                        <div class="symbol symbol-fixed d-flex align-items-center position-relative">
                            <img src={row.original.participant.personne?.upload?.urlMiniature} alt="image personne"/>
                            <div className="ms-3 d-flex">
                                <p className="me-2">{row.original.participant.personne.prenom + " " + row.original.participant.personne.nom}</p>
                                <a
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                    className='svg-icon svg-icon-1 cursor-pointer'><Art005/></a></div>
                        </div>
                    </div>);
                    result.push()
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: 'Téléphones',
                accessor: 'participant.personne.tel1',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                    result.push(<br/>)
                    result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                    return result;
                },
            },
            {
                Header: 'Email',
                accessor: 'participant.personne.email',
                minWidth: 250,
            },
            {
                Header: 'Envoyer email',
                id: 'ordrePassageMail',
                minWidth: 190,
                Cell: ({row}) => {
                    return <>
                        <div style={{
                            border: "solid 1px black",
                            padding: 2,
                            width: 190
                        }} title={"envoyer mail ordre de passage"}
                             onClick={() => {
                                 BtSwal.fire({
                                     title: `<b>Êtes-vous sûr de vouloir envoyer le mail ordre de passage à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                     showDenyButton: true,
                                     confirmButtonText: `Oui`,
                                     denyButtonText: `Annuler`,
                                     icon: 'info',
                                 }).then((result) => {
                                     if (result.isConfirmed) {
                                         sendMailOrdreOneCorrespondant.mutate(row.original.id)
                                     } else if (result.isDenied) {
                                         BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                     }
                                 })
                             }}
                             className="cursor-pointer">
                        <span style={{
                            fill: "black",
                        }} className='svg-icon svg-icon-1 me-2'><Gen016/> Email ordre passage</span></div>
                        {row?.original?.isMaitreDuTemps && <div style={{
                            border: "solid 1px black",
                            padding: 2,
                            width: 190
                        }} onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le mail maitre du temps à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailMaitreOneCorrespondant.mutate(row.original.id)
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }}
                        title={"envoyer mail maitre du temps"} className="cursor-pointer mt-2">
                        <span style={{
                            fill: "black",
                        }} className='svg-icon svg-icon-1 me-2'><Gen016/> Email maitre du temps</span></div>}
                    </>;
                },
            },
            {
                Header: 'Envoyer sms',
                id: 'ordrePassageSms',
                minWidth: 50,
                Cell: ({row}) => {
                    return <>
                        <div style={{
                            border: "solid 1px black",
                            padding: 2,
                            width: 190
                        }} title={"envoyer sms ordre de passage"}
                             onClick={() => {
                                 BtSwal.fire({
                                     title: `<b>Êtes-vous sûr de vouloir envoyer le sms ordre de passage à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                     showDenyButton: true,
                                     confirmButtonText: `Oui`,
                                     denyButtonText: `Annuler`,
                                     icon: 'info',
                                 }).then((result) => {
                                     if (result.isConfirmed) {
                                         sendSmsOrdreOneCorrespondant.mutate(row.original.id)
                                     } else if (result.isDenied) {
                                         BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                                     }
                                 })
                             }}
                             className="cursor-pointer">
                        <span style={{
                            fill: "black",
                        }} className='svg-icon svg-icon-1 me-2'><Gen016/> Sms ordre passage</span></div>
                        {row?.original?.isMaitreDuTemps && <div style={{
                            border: "solid 1px black",
                            padding: 2,
                            width: 190
                        }} onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le sms maitre du temps à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendSmsMaitreOneCorrespondant.mutate(row.original.id)
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }}
                        title={"envoyer mail maitre du temps"} className="cursor-pointer mt-2">
                        <span style={{
                            fill: "black",
                        }} className='svg-icon svg-icon-1 me-2'><Gen016/> Sms maitre du temps</span></div>}
                    </>;
                },
            },
        ]
        return tmp;
    }, [])
    return <div>
            <div className="d-flex justify-content-between mb-2">
                <div>
                    <div className="d-flex justify-content-start">
                        <Button onClick={() => {
                            if (speedSnap?.speedBusiness?.ref) {
                                let base_url = window.location.origin;
                                window.open(base_url + "/" + snapAuth?.agence?.dossierUpload + "/speedBusiness/presentation?ref=" + speedSnap.speedBusiness.ref, '_blank');
                            }
                        }} variant="primary">
                            Go
                        </Button>
                        <Button onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le mail d'ordre de passage à tous les participants ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailRappel.mutate({
                                        id: speedSnap?.speedBusiness?.id,
                                        type: 'emailPassage',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }} variant="danger" className="ms-3">
                            Envoyer l'email d'ordre de passage aux présents
                        </Button>
                        <Button onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le sms d'ordre de passage à tous les participants ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailRappel.mutate({
                                        id: speedSnap?.speedBusiness?.id,
                                        type: 'smsPassage',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }} variant="secondary" className="ms-3">
                            Envoyer le sms d'ordre de passage aux présents
                        </Button>
                    </div>
                    <div className="d-flex justify-content-start mt-3" style={{
                        marginLeft: 60
                    }}>
                        <Button onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le mail maitre du temps à tous les maitres du temps ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailRappel.mutate({
                                        id: speedSnap?.speedBusiness?.id,
                                        type: 'emailMaitreTemps',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }} variant="danger" className="ms-3">
                            Envoyer l'email à tous les maitres du temps
                        </Button>
                        <Button onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le sms maitre du temps à tous les maitres du temps ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailRappel.mutate({
                                        id: speedSnap?.speedBusiness?.id,
                                        type: 'smsMaitreTemps',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }} variant="secondary" className="ms-3">
                            Envoyer le sms à tous les maitres du temps
                        </Button>
                    </div>
                </div>
                <div>
                    <div style={{}}>
                        <span>Heure programmation mails et sms</span><Flatpickr
                        className={"form-control mt-2"}
                        display={"hidden"}
                        defaultValue={dateEnvoi}
                        onChange={date => {
                            setDateEnvoi(moment(date[0]).format("DD/MM/YYYY à HH:mm"));
                        }}
                        options={{
                            locale: French,
                            dateFormat: 'd/m/Y à H:i',
                            enableTime: true,
                        }}
                    />
                    </div>
                </div>
            </div>
        <div style={{}} className="m-auto">
            <h3>Liste des participants</h3>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <MetaTable
                ref={tableRef}
                className='mt-5'
                height={500}
                url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
                keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
                columns={columns}
                search={debouncedSearch}
                colSearch={colSearch}
                showFiltres={true}/>
        </div>
        {/*
            // todo rajouter la gestion des excels
        */}

    </div>
})

export default Lancement;