import React from "react";
import Select2 from "../../../../components/Select2/Select2";
import {Button, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faWrench} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import {ReactComponent as Gen027} from "@assets/icons/gen027.svg";
import useResendInvitationForOneCorrespondant
    from "../../../../requests/communication/invitations/useResendInvitationForOneCorrespondant";
import {baseURL} from "../../../../config";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import useDeleteAccompagnantInvitation
    from "../../../../requests/communication/invitations/useDeleteAccompagnantInvitation";

function TabGestionAccompagnants({ setValue, getValues, tabAccompagnants, convertTabOptionsParticipation, data, update, setUpdate, setCurrentEditAccompagnant, setCurrentEditIndex,setShowEditModal, setAccompagnantAdd, accompagnantAdd  }) {

    const resendInvitation = useResendInvitationForOneCorrespondant();
    const deleteAccompagnantInvitation = useDeleteAccompagnantInvitation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";

    const tabFormateAccompagnant = Object.values(tabAccompagnants).map((value, index) =>
        <tr style={{display : "flex", justifyContent :"start", alignItems :"center"}} key={index}>
            <td style={{flex : "0.1"}}>
                {(!value.destinataire.personne.id) && !value.tabPaiements[1].actif ?
                    <div className="d-flex">
                            <span
                                style={{width: "25px"}}
                                onClick={() => {
                                    setCurrentEditIndex(index)
                                    setCurrentEditAccompagnant(value)
                                    setShowEditModal(true)
                                }}
                                className='cursor-pointer'><Art005 /></span>
                        <span
                            style={{width: "25px"}}
                            onClick={() => {
                                let tmp = (getValues("tabAccompagnants"))
                                tmp.splice(index, 1)
                                setValue("tabAccompagnants", tmp)
                                setAccompagnantAdd(accompagnantAdd - 1)
                                setUpdate(update+1)}}
                            className='cursor-pointer'><Gen027 /></span>
                    </div>
                : <span
                        onClick={() => {
                            if (!value.tabPaiements[1].actif && !value.paieSaPart && !value.tabPaiements[3].actif && !value.tabPaiements[4].actif){
                                let tmp = (getValues("tabAccompagnants"));
                                tmp.splice(index, 1);
                                setValue("tabAccompagnants", tmp);
                                setAccompagnantAdd(accompagnantAdd - 1);
                                setUpdate(update+1);
                                let data = {
                                    id : value?.id,
                                    ref : ref
                                }
                                deleteAccompagnantInvitation.mutate(data);
                            }
                            else if (value.tabPaiements[3].actif || value.tabPaiements[4].actif){
                                showErrorAlert('Erreur de suppression', 'Vous ne pouvez pas supprimer une personne dont la participation est validé.');
                            }
                            else if (value.paieSaPart){
                                showErrorAlert('Erreur de suppression', 'Vous ne pouvez pas supprimer une personne dont l\'invitation  déjà été envoyée.');
                            }
                            else {
                                showErrorAlert('Erreur de suppression', 'Vous ne pouvez pas supprimer une personne dont la part a déjà été réglée.');
                            }
                        }}
                        className='svg-icon svg-icon-1 me-2 cursor-pointer'><Gen027 /></span>
                }
            </td>
            <td style={{flex : "1.5"}}>{value.destinataire.personne.prenom} {value.destinataire.personne.nom} ({value.destinataire.personne.email})</td>
            <td style={{flex : "1.5"}}><div className="btn-group" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                <label
                    key={index}
                    className={`small d-flex align-items-center p-3 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.tabPaiements[1].actif || value.destinataire.personne.id ? `disabled` : ''} ${!value.paieSaPart ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            let tmp = (getValues("tabAccompagnants").find(obj => obj.destinataire.personne.prenom == value.destinataire.personne.prenom && obj.destinataire.personne.nom == value.destinataire.personne.nom && obj.destinataire.personne.email == value.destinataire.personne.email))
                            tmp.paieSaPart = false
                            setValue("tabAccompagnants."+index, tmp)
                            setUpdate(update+1)
                        }}
                        className="btn-check" type="radio" name="method"/>
                    <span style={{fontSize: '10px'}}>Prendre en charge son inscription</span></label>
                <label
                    key={index}
                    className={`small p-3 d-flex align-items-center btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.tabPaiements[1].actif || value.destinataire.personne.id ? `disabled` : ''} ${value.paieSaPart ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            let tmp = (getValues("tabAccompagnants").find(obj => obj.destinataire.personne.prenom == value.destinataire.personne.prenom && obj.destinataire.personne.nom == value.destinataire.personne.nom && obj.destinataire.personne.email == value.destinataire.personne.email))
                            tmp.paieSaPart = true;
                            tmp.dateEnvoiInvitation = null;
                            setValue("tabAccompagnants."+index, tmp)
                            setUpdate(update+1)
                        }}
                        className="btn-check" type="radio" name="method"/>
                    <span style={{fontSize: '10px'}}>Lui transférer l'invitation</span></label>
            </div></td>
            <td style={{flex : "1"}}>{value.tabPaiements[1].actif ?
                    <div className="d-flex align-items-center">
                        <span className="fa-stack fa-2x me-4">
                            <i style={{fontSize:"25px"}} className="fas fa-credit-card fa-stack-1x"></i>
                            <i style={{color:"rgba(0,0,0,0.69)", top:"-15px", right:"-34px", fontSize:"20px"}} className="fas fa-circle fa-stack-1x"></i>
                            <i style={{color:"#9fbe22", top:"-15px", right:"-34px", fontSize:"20px"}} className="fas fa-check-circle fa-stack-1x"></i>
                        </span>
                        <span
                            onClick={() => {
                                window.open(baseURL+"/stripe/recu?referenceCharge="+value?.referenceCharge, "_blank")
                            }}
                            className="me-2 cursor-pointer"><i style={{fontSize: "25px"}} className="fas fa-receipt text-center"></i></span>
                        <span
                            onClick={() => {
                                window.open(baseURL+"/facture/afficher?referencePaiement="+value?.referencePaiement, "_blank")
                            }}
                            className="cursor-pointer"><i style={{fontSize: "25px"}} className="fas fa-file-invoice text-center"></i></span>
                    </div>
                // <p className='alert alert-info' style={{width:"fit-content"}}>Payé</p>

                : <span className="fa-stack fa-2x">
                        <i style={{fontSize:"25px"}} className="fas fa-credit-card fa-stack-1x"></i>
                        {/*<i style={{color:"rgba(0,0,0,0.69)", top:"-15px", right:"-34px", fontSize:"20px"}} className="fas fa-circle fa-stack-1x"></i>*/}
                        <i style={{color:"#f06445", top:"-15px", right:"-34px", fontSize:"20px"}} className="fas fa-hourglass fa-stack-1x"></i>
                    </span>}
            </td>
            {(!value.paieSaPart && convertTabOptionsParticipation.length > 0 )?
                <td style={{flex : "1"}}>
                    <Select2
                        disabled={!!value.tabPaiements[1].actif}
                        allowClear={true}
                        data={data}
                        selected={value.optionParticipation != null ? [value.optionParticipation?.id?.toString()] : null}
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            const res = convertTabOptionsParticipation.find(
                                exp => exp.id.toString() === selected
                            );
                            let tmp = (getValues("tabAccompagnants").find(obj => obj.destinataire.personne.prenom == value.destinataire.personne.prenom && obj.destinataire.personne.nom == value.destinataire.personne.nom && obj.destinataire.personne.email == value.destinataire.personne.email))
                            tmp.optionParticipation = res
                            setValue("tabAccompagnants." + index, tmp)
                            setUpdate(update + 1)
                        }}
                        minimumResultsForSearch={3}
                        placeholder='Selectionner une option de participation'

                    />
                </td> :
                <td style={{flex : "1"}}>
                    <div className="d-flex">
                    <span
                        onClick={() => {
                            resendInvitation.mutate(value.id)
                        }}
                        title="Renvoyer l'invitation"
                        className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span>
                        {
                            (value?.tabEtatsInvitation && value?.paieSaPart)?

                                <div className="btn-group" data-kt-buttons="true"
                                     data-kt-buttons-target="[data-kt-button]">
                                    <label
                                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.tabEtatsInvitation[0].actif ? `active` : ``}`}
                                        data-kt-button="true">
                                        <input
                                            className="btn-check" type="radio" name="method"/>
                                        <span style={{fontSize: '10px'}}>En attente</span></label>
                                    <label
                                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.tabEtatsInvitation[1].actif ? `active` : ``}`}
                                        data-kt-button="true">
                                        <input
                                            className="btn-check" type="radio" name="method"/>
                                        <span style={{fontSize: '10px'}}>Paiement en cours</span></label>
                                    <label
                                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.tabEtatsInvitation[2].actif ? `active` : ``}`}
                                        data-kt-button="true">
                                        <input
                                            className="btn-check" type="radio" name="method"/>
                                        <span style={{fontSize: '10px'}}>Accepte</span></label>
                                    <label
                                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.tabEtatsInvitation[3].actif ? `active` : ``}`}
                                        data-kt-button="true">
                                        <input
                                            className="btn-check" type="radio" name="method"/>
                                        <span style={{fontSize: '10px'}}>Décline</span></label>
                                </div>
                                : ""
                        }
                    </div>
                </td>
            }
        </tr>
    );

    return tabFormateAccompagnant;
}

export default TabGestionAccompagnants;