import {useMutation} from "react-query";
import toast from "react-hot-toast";
import api from "@services/api";

export default function useResendMailMaitreDuTempsForOneCorrespondant() {

    return useMutation(resend, {
        onSuccess: () => {
            toast.success('Le mail a été envoyé', {
                duration:5000
            });
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });
}

function resend(id) {

    if (id != null)
        return api.post(`participantRencontreSpeedBusiness/${id}/envoyerEmailMaitreTemps`).json();

}