import {useSnapshot} from "valtio";
import auth from "../../../../../services/auth";
import useGetInvitationByInscriptionLibre
    from "../../../../../requests/communication/invitations/useGetInvitationByInscriptionLibre";
import React, {useEffect, useRef, useState} from "react";
import LoadingView from "../../../../../views/LoadingView/LoadingView";
import {useForm} from "react-hook-form";
import {Button, Card, Col, Form, FormGroup, Row} from "react-bootstrap";
import CiviliteSelection from "../../../../../views/personne/CiviliteSelection/CiviliteSelection";
import useAddCorrespondantByInscriptionLibre
    from "../../../../../requests/communication/invitations/useAddCorrespondantByInscriptionLibre";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";
import {useQueryClient} from "react-query";
import {
    getEntreprise,
    getObjetPersonneInscriptionLibre
} from "./objet";
import {emailPattern, telAnnuairePattern} from "../../../../../functions/patterns";
import BlockUI from "../../../../../components/BlockUI/BlockUI";
import AccepteViewInscriptionLibre
    from "./AccepteInscriptionLibreView";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";

function PresentationView(){

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const snapAuth = useSnapshot(auth);
    const repondreInvitationByInscription = useGetInvitationByInscriptionLibre(ref);
    const { register, reset, setError, clearErrors, trigger, control, getValues, handleSubmit, setValue, unregister,
        formState: { errors } } = useForm({
            defaultValues: {
                personne : getObjetPersonneInscriptionLibre(),
                entreprise: getEntreprise(),
                ref : ref
            }
    });
    const recaptchaRef = useRef();
    const [stateStep, setStateStep] = useState(0);
    const queryClient = useQueryClient();
    const [initCorrespondant, setInitCorrespondant] = useState(false);
    const addCorrespondant = useAddCorrespondantByInscriptionLibre({
        onSuccess: (res) => {
            setDataCorrespondant({
                accompagnateur: res.accompagnateur,
                correspondant: res.correspondantInvitation,
                refI: res.correspondantInvitation.urlAccepte.split("ref=")[1]
            })
            setValue("personne", res.correspondantInvitation.destinataire.personne)
            setValue("entreprise", Object.values(res.correspondantInvitation.destinataire.tabEntreprises)[0])
            localStorage.setItem("dataInscriptionLibre"+ref,JSON.stringify(getValues()));
            setStateStep(1)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement de la personne", err.message);
        },
    });
    const [dataCorrespondant, setDataCorrespondant] = useState(
        {
            correspondant: null,
            accompagnateur: null,
            refI: null
        }
    )
    //Au premier render check s'il y a des données dans le localStorage si c'est le cas je reset le formulaire
    useEffect(() => {
        if (localStorage.getItem("dataInscriptionLibre"+ref)){
            BtSwal.fire({
                title: `<b>Voulez-vous reprendre là où vous en étiez ?</b>`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Non`,
                icon: "info"
            }).then((result) => {
                if (result.isConfirmed) {
                    reset(JSON.parse(localStorage.getItem("dataInscriptionLibre"+ref)));
                }
            })
        }
    }, []);
    const steps = [
        {
            name: 'Personne',
            renderComponent: (ref) =>  <Row>
                <Col sm={4}>
                    <Form.Group className={'mb-3'}>
                        <Form.Label className='required'>Civilité</Form.Label>
                        <CiviliteSelection isSolid={false} control={control}
                                           name={`personne.civilite`} rules={{required: true}}/>
                        {errors.personne?.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Prénom</Form.Label>
                        <Form.Control maxLength={20} className='form-control'
                                      {...register(`personne.prenom`, {required : true})}
                        />
                        {errors.personne?.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Nom</Form.Label>
                        <Form.Control maxLength={50} className='form-control'

                                      {...register(`personne.nom`, {required : true})}

                        />
                        {errors.personne?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Email</Form.Label>
                        <Form.Control maxLength={50} className='form-control'
                                      {...register(`personne.email`, {required : true, pattern: emailPattern()})}


                        />
                        {errors.personne?.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Téléphone</Form.Label>
                        <Form.Control className='form-control'
                                      maxLength={14}
                                      onKeyUp={(e) => {
                                          e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                                          let countNumber = 0;
                                          for (let i=0; i<e.target.value.length; i++ ){
                                              if (!isNaN(parseInt(e.target.value[i]))){
                                                  countNumber = countNumber +1;
                                              }
                                          }
                                          if (countNumber > 10){
                                              setError("personne.tel1")
                                          }else if (countNumber != 10 && countNumber != 0){
                                              setError("personne.tel1")
                                          }else {
                                              clearErrors("personne.tel1")
                                          }
                                      }}
                                      {...register(`personne.tel1`, {required : true, pattern: telAnnuairePattern()})}

                        />

                        {errors.personne?.tel1 && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Nom entreprise</Form.Label>
                        <Form.Control maxLength={50} className='form-control'
                                      {...register(`entreprise.nom`, {required : true})}
                        />
                        {errors.entreprise?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup as={Col}>
                        <Form.Label className='required'>Activité</Form.Label>
                        <Form.Control maxLength={50} className='form-control'
                                      {...register(`entreprise.activite`, {required : true})}
                        />
                        {errors.entreprise?.activite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </FormGroup>
                </Col>
                <ReCAPTCHA
                    size="normal"
                    ref={recaptchaRef}
                    style={{ display: "inline-block", margin:"10px auto", width: "fit-content" }}
                    sitekey="6LdWJfMpAAAAAEnzxsK0AQsca0EeTZbmf3susH4P"
                />
            </Row>
        },
        {
            name: 'Option et accompagnants',
            renderComponent: (ref) => <AccepteViewInscriptionLibre
                dataCorrespondant={dataCorrespondant.correspondant}
                dataInvitation={repondreInvitationByInscription?.data}
                dataAccompagnateur={dataCorrespondant.accompagnateur}
                isRelance={false}
                refI={dataCorrespondant.refI}
                goToStep={goToStep}
            />
        }
    ];


    async function goToStep() {
        if (stateStep == 0){
            await handleSubmit((data) => {
                if (recaptchaRef.current.getValue()) {
                    addCorrespondant.mutate(data)
                }else {
                    BtSwal.fire("Merci de cocher le captcha", "", "info")
                }
            })()
        }else {
            setStateStep(0)
        }
    }

    if (repondreInvitationByInscription.isError || ref === ""){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Répondre à l'invitation</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <p>{repondreInvitationByInscription.error?.message} </p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!repondreInvitationByInscription.isSuccess){
        return <LoadingView/>
    }

    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-fluid'>
            <div className='mt-5 d-flex flex-column flex-column-fluid card-body p-0'>
                <div className='mx-auto w-lg-50 w-md-100'>
                    <h1 className="mx-10 text-center alert-info-color">{repondreInvitationByInscription.data?.sujet}</h1>
                    <h2 className="mx-10 text-center ">{repondreInvitationByInscription.data?.dateEvenementFormatee}</h2>
                    {repondreInvitationByInscription.data?.uploadInscriptionLibre?.urlOriginale && <img style={{
                        maxWidth: "100%",
                        margin: "10px auto",
                        display: "block",
                        border: "solid 1px lightgrey"
                    }}
                          src={repondreInvitationByInscription.data?.uploadInscriptionLibre?.urlOriginale} alt=""/>}

                    {repondreInvitationByInscription.data?.descriptionInscriptionLibre && <div className="p-3 mb-5 mx-10 bg-white rounded pb-4">
                <span
                    dangerouslySetInnerHTML={{__html: repondreInvitationByInscription.data?.descriptionInscriptionLibre}}></span>
                    </div>}

                    {repondreInvitationByInscription.data?.adresse &&
                        <div className="p-3 mt-5 mx-10 bg-white rounded pb-4">
                            <h3> <i className="fa fa-map-marked-alt"></i>   Adresse</h3>
                            <div className="d-flex align-items-center" style={{flex: 1}}>
                                <div className="d-flex">
                                    <p className=""><i
                                        className=""></i> {repondreInvitationByInscription.data?.preludeAdresse} <br/>
                                        {repondreInvitationByInscription.data?.adresse} <br/>
                                        {repondreInvitationByInscription.data?.codePostal}, {repondreInvitationByInscription.data?.ville}
                                    </p>
                                </div>
                            </div>
                            <div style={{flex: 1}}>
                                <iframe width="100%" height="300" frameBorder="0" scrolling="no" marginHeight="0"
                                        marginWidth="0"
                                        src={"https://maps.google.com/maps?width=100%&height=600&hl=fr&q=" + encodeURIComponent(repondreInvitationByInscription.data?.adresse + " " + repondreInvitationByInscription.data?.codePostal) + "&t=&z=14&ie=UTF8&iwloc=B&output=embed"}>
                                    <a href="https://www.gps.ie/">gps trackers</a></iframe>
                            </div>
                        </div>}

                    {repondreInvitationByInscription?.data?.dateReponse && <div className="p-3 mt-5 mb-5 mx-10 bg-white rounded pb-4 text-center">
                        <b className="text-center">La date limite de réponse est
                            le {repondreInvitationByInscription?.data?.dateReponse}</b>
                    </div>}

                    {((repondreInvitationByInscription?.data?.dateReponse == null || (moment(repondreInvitationByInscription?.data?.dateReponse, "DD/MM/YYYY") > moment())) && moment(Object.values(repondreInvitationByInscription.data?.tabPeriodes)[0].debut, "DD/MM/YYYY à HH:mm") >= moment() ) && <div className="p-3 mt-5 mx-10 bg-white rounded pb-4 mb-5">
                        <div className='w-100 stepper stepper-links d-flex flex-column between'>
                            <div className='stepper-nav'>
                                {steps.map((step, index) => <div
                                    className={`stepper-item ${index === stateStep ? 'current' : ''} ${index < stateStep ? 'completed' : ''} hoverable`}
                                    key={step.name}
                                >
                                    <h3 className='stepper-title'>{step.name}</h3>
                                </div>)}
                            </div>
                        </div>

                        <BlockUI loading={addCorrespondant.isLoading} className='overflow-scroll modal-body'>
                            {steps[stateStep]?.renderComponent()}
                        </BlockUI>

                        <div className="d-flex justify-content-around mt-5">
                            {stateStep < steps.length - 1 && <Button
                                text='Suivant'
                                variant='secondary'
                                className='ms-auto'
                                onClick={() => {
                                    goToStep(stateStep + 1)
                                }}
                            >Suivant</Button>}
                        </div>

                    </div>}
                    {(moment(Object.values(repondreInvitationByInscription.data?.tabPeriodes)[0].debut, "DD/MM/YYYY à HH:mm") < moment() )&&
                        <div className="p-3 mb-5 mx-10 bg-white rounded pb-4">
                            <span className="fw-bold">Les inscriptions sont terminées.</span>
                        </div>

                    }

                </div>

            </div>
        </div>
    </div>
}

export default PresentationView