import { useMemo } from "react";

export function useDatatableColumns({columns, colSearch, search, sortFilter, tabFilter = {}}) {

    if (sortFilter != null && Object.keys(sortFilter).length === 0){
        sortFilter = {0: {
            column: 0,
            dir: "desc"
        }}
    }

    return useMemo(() => {
        let res = {
            columns: {},
            order: sortFilter,
            search: { value: search ?? '', regex: false }
        };
        if (Object.keys(tabFilter).length > 0){
            for (const [key, value] of Object.entries(tabFilter)) {
                res = {
                    ...res,
                    [key]: value
                }
            }
        }

        let offset = 0;
        columns.forEach((col, index) => {
            if (col.accessor != null)
                res.columns[index - offset] = {
                    data: col.accessor,
                    searchable: !col.disableSearch,
                    orderable: !col.disableSortBy,
                    searchOperator: col.searchOperator ? col.searchOperator : "",
                    search: {
                        value: colSearch?.hasOwnProperty(col.accessor) ? colSearch[col.accessor] ?? '' : '',
                        regex: false,
                    },
                };
            else
                offset++;
        });

        return res;
    }, [columns, colSearch, search, sortFilter, tabFilter]);
}